import { State } from '$/app/store/user/user.state';
import { createFeatureSelector, createSelector } from '@ngrx/store';

// Selector Helpers
const getUser = (state: State) => state.user;
const getFacilityUserId = (state: State) => state.facilityUserId;
const getFacilityId = (state: State) => state.facilityId;
const getIsAuthenticated = (state: State) => state.isAuthenticated;
const getPermissions = (state: State) => state.permissions;
const getGeofenceEnforced = (state: State) => state.geofenceEnforced;

// User State Selector
const selectUserState = createFeatureSelector<State>('user');

// User Selectors
const selectUser = createSelector(selectUserState, getUser);
const selectFacilityUserId = createSelector(selectUserState, getFacilityUserId);
const selectFacilityId = createSelector(selectUserState, getFacilityId);
const selectPermissions = createSelector(selectUserState, getPermissions);

const selectLoading = createSelector(selectUserState, (state) => state.loading);

const selectGeofenceEnforced = createSelector(
  selectUserState,
  getGeofenceEnforced
);

const selectIsAuthenticated = createSelector(
  selectUserState,
  getIsAuthenticated
);

const selectUserId = createSelector(
  selectUserState,
  (state) => state?.user?.id
);

const selectUserFacilities = createSelector(selectUserState, (state) => {
  return state.facilityList;
});

export const UserSelectors = {
  selectUserState,
  selectUser,
  selectUserId,
  selectFacilityUserId,
  selectFacilityId,
  selectPermissions,
  selectUserFacilities,
  selectGeofenceEnforced,
  selectIsAuthenticated,
  selectLoading
};
